import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "laravel-forge"
    }}>{`Laravel Forge`}</h1>
    <h2 {...{
      "id": "server"
    }}>{`Server`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add the clients server as a `}<a parentName="p" {...{
            "href": "https://forge.laravel.com/user-profile/server-providers"
          }}>{`server provider`}</a>{` in our Laravel Forge account. Use the AWS credentials of the `}<a parentName="p" {...{
            "href": "#aws-user-forge"
          }}><inlineCode parentName="a">{`forge`}</inlineCode>{` user`}</a>{`.
`}<img alt="Step 1" src={require("./LaravelForge/forge-1.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://forge.laravel.com/servers"
          }}>{`Go back to servers (main page)`}</a>{` and create a new AWS server.
`}<img alt="Step 2" src={require("./LaravelForge/forge-2.png")} /></p>
      </li>
    </ol>
    <p>{`Follow these options:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Option`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Note`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`App Server`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lowercase`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Select the nearest region to the client location`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Server Size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`At least t3.small`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PHP Version`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8.0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VPC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If you select a pre-existing one, be sure to follow `}<a parentName="td" {...{
              "href": "https://forge.laravel.com/docs/1.0/servers/providers.html#amazon-aws-api-access"
            }}>{`these`}</a>{` requirements`}</td>
        </tr>
      </tbody>
    </table>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`The new server takes around 10 minutes, till its provisioned by Laravel Forge. Once it's provisioned you will get an email, with the server credentials too. Make sure to save those credentials in 1PW.
`}<img alt="Server provisioned email" src={require("./LaravelForge/forge-server-provisioned-email.png")} /></li>
    </ol>
    <h2 {...{
      "id": "database"
    }}>{`Database`}</h2>
    <h3 {...{
      "id": "add-new-database"
    }}>{`Add new database`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to Laravel Forge Server and switch to the `}<inlineCode parentName="p">{`Database`}</inlineCode>{` tab.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a new database, set a username and password.
`}<img alt="Step 4" src={require("./LaravelForge/forge-6.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Dont forget to store the DB credentials in 1PW!`}</p>
      </li>
    </ol>
    <hr></hr>
    <h3 {...{
      "id": "scheduled-backups"
    }}>{`Scheduled backups`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Go to Laravel Forge Server and switch to the `}<inlineCode parentName="p">{`Backups`}</inlineCode>{` tab.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a new `}<inlineCode parentName="p">{`Backup Configuration`}</inlineCode>{`.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Change the configuration depending on your needs`}</li>
          <li parentName="ul">{`Add the AWS bucket login credentials for the `}<a parentName="li" {...{
              "href": "#aws-user-db-snapshots"
            }}>{`db-snapshots user`}</a>{`.`}</li>
          <li parentName="ul">{`If you plan on creating a staging and production database, it may make sense to create a unique `}<inlineCode parentName="li">{`Storage Directory`}</inlineCode>{` for each environment.
`}<img alt="Step 5" src={require("./LaravelForge/forge-8.png")} /></li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the database you want to backup and click on `}<inlineCode parentName="p">{`Create`}</inlineCode>{`.
`}<img alt="Step 6" src={require("./LaravelForge/forge-9.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "ssh-key"
    }}>{`SSH Key`}</h2>
    <p>{`By default, Laravel Forge shares the same development key through all servers.
For security reasons, we suggest removing it and granting access to single developers.
If you need access, ask your project administrator to add your key.`}</p>
    <h3 {...{
      "id": "test-the-connection-optional"
    }}>{`Test the connection (optional)`}</h3>
    <ul>
      <li parentName="ul">{`Add this snippet to the `}<inlineCode parentName="li">{`~/.ssh/config`}</inlineCode>{` file:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Host PROJECT_NAME
  Hostname PUBLIC_IP_ADDRESS
  User forge
  UseKeychain yes
  IdentityFile ~/.ssh/your_private_key_name
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      